import { navigate } from 'gatsby'
export { default as CenteredLayout } from './layouts/CenteredLayout'
export { default as NakedLayout } from './layouts/NakedLayout'
export { default as SplashPage } from './layouts/SplashPage'
export { default as TwoColumnLayout } from './layouts/TwoColumnLayout'

export const Redirect = ({ to, options }) => {
  navigate(to, options)
  return null
}
