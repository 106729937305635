import axios from '../axios'
import { apiUrl } from '..'

class GeneralApiService {
  getPage = (id) => axios.get(apiUrl('/v1/page/' + id))

  getTimezones = () => axios.get(apiUrl('/v1/general/timezones'))
}

export default GeneralApiService
