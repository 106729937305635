import React, { useContext } from 'react'
import { RootProvider } from '../../config/rootContext'
import { ThemeContext, ThemeProvider, MODES } from '../../config/themeContext'
import { Helmet } from 'react-helmet'
import { ToastContainer } from 'react-toastify'
import classnames from 'classnames'

export type RootHelmetProps = {
  isHeightFull?: boolean
  useDarkMode?: boolean
  htmlClassname?: string
  bodyClassName?: string
}

const RootHelmet = ({
  isHeightFull,
  useDarkMode,
  htmlClassname,
  bodyClassName,
}: RootHelmetProps) => {
  const { theme } = useContext(ThemeContext)

  return (
    <Helmet>
      <html
        lang="en-GB"
        className={classnames(
          {
            'h-full': isHeightFull === true,
            dark: theme === MODES.DARK && useDarkMode === true,
          },
          htmlClassname,
        )}
      />

      <body
        className={classnames(
          {
            'bg-white dark:bg-gray-900': typeof bodyClassName === 'undefined',
            'h-full': isHeightFull === true,
          },
          bodyClassName,
        )}
      />

      <meta charSet="utf-8" />

      {/* FAVICONS */}
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
    </Helmet>
  )
}

export type RootProps = {
  isHeightFull?: boolean
  useDarkMode?: boolean
  htmlClassname?: string
  bodyClassName?: string
  children: React.ReactNode
}

const Root = ({ children, ...rest }) => {
  return (
    <RootProvider>
      <ThemeProvider>
        <RootHelmet {...rest} />

        {children}

        <ToastContainer />
      </ThemeProvider>
    </RootProvider>
  )
}

export default Root
