import axios from '../axios'
import { apiUrl } from '..'

class QuestionApiService {
  getGeneratedQuestion = (questionId, level = null) =>
    level === null
      ? axios.get(apiUrl('/v1/questions/generate/' + questionId))
      : axios.get(apiUrl('/v1/questions/generate/' + questionId + '/' + level))
}

export default QuestionApiService
