import React, { useEffect, useState } from 'react'

const defaultValue = {}

export const RootContext = React.createContext(defaultValue)

const getInitialFlag = (key, defaultValue) => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const storedPrefs = window.localStorage.getItem(key)

    if (typeof storedPrefs === 'string') {
      return storedPrefs === 'true'
    }
  }

  return defaultValue
}

export const RootProvider = ({ children }) => {
  const [autoplayFlag, setAutoplayFlag] = useState(getInitialFlag('voice-autoplay', true))

  useEffect(() => {
    localStorage.setItem('voice-autoplay', autoplayFlag)
  }, [autoplayFlag])

  return (
    <RootContext.Provider
      value={{
        autoplayFlag,
        setAutoplayFlag,
      }}
    >
      {children}
    </RootContext.Provider>
  )
}
