import axios from 'axios'
import { navigate } from 'gatsby'
import ROUTES from '../config/routes'
import { notificationUnknownError } from '../utils/notification'

const instance = axios.create({
  //validateStatus: (status) => status >= 200 && status < 300
  withCredentials: true,
})

const handle422 = (error) => {
  return (fieldRules) => {
    error.response.data.forEach((fieldData) => {
      const { field, message } = fieldData

      if (typeof fieldRules[field] === 'function') {
        fieldRules[field](message)
      }
    })
  }
}

const handle404 = (error) => {
  return (customMessage) => {
    notificationUnknownError(customMessage || 'Page not found')
    navigate(ROUTES.home)
    throw error
  }
}

const handleDefault = (error) => {
  return () => {
    notificationUnknownError(error)
    throw error
  }
}

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    error.handle422 = handle422(error)
    error.handle404 = handle404(error)
    error.handleDefault = handleDefault(error)

    return Promise.reject(error)
  },
)

export default instance
