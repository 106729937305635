import React from 'react'
import Root, { RootProps } from '../Root'
import classnames from 'classnames'

type TwoColumnLayoutProps = {
  rootProps: RootProps
  children: React.ReactNode
  rightChildren: React.ReactNode
  classNames: string
  containerClassnames: string
  leftClassnames: string
  rightClassnames: string
}

const TwoColumnLayout = ({
  children,
  rightChildren,
  rootProps,
  containerClassnames,
  leftClassnames,
  rightClassnames,
}: TwoColumnLayoutProps) => {
  return (
    <Root isHeightFull {...rootProps}>
      <div className={classnames('flex items-center h-full', containerClassnames)}>
        <div className={classnames('h-full w-full lg:w-2/3 relative', leftClassnames)}>
          {children}
        </div>

        <div className={classnames('h-full w-1/3 fixed right-0 hidden lg:block', rightClassnames)}>
          {rightChildren}
        </div>
      </div>
    </Root>
  )
}

export default TwoColumnLayout
