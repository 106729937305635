const fetchAll = async (getRequest, getParams) => {
    const allData = {
        data: [],
        totalCount: 0,
        defaultPageSize: 0,
        totalPages: 0
    };

    const {data: requestData} = await getRequest(getParams)
    const {data, totalCount, defaultPageSize} = requestData

    allData.data = [...allData.data, ...data]
    allData.totalCount = totalCount
    allData.defaultPageSize = defaultPageSize
    allData.totalPages = Math.ceil(totalCount / defaultPageSize)

    const extraRequests = []

    for (let i = 1; i < allData.totalPages; i++) {
        const clonedParams = {...getParams}
        clonedParams.page = i + 1
        extraRequests.push(getRequest(clonedParams))
    }

    const results = await Promise.all(extraRequests)

    results.forEach((result) => {
        allData.data = [...allData.data, ...result.data.data]
    })

    return allData
}

export default fetchAll
