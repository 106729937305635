import axios from '../axios'
import { apiUrl } from '..'

class AuthApiService {
  postJoinWaitingList = (name, email, type) =>
    axios.post(apiUrl('/v1/leads'), { name: name, email: email, type: type })

  postSignIn = (email, password, rememberMe) =>
    axios.post(apiUrl('/v1/auth/signin'), {
      email: email,
      password: password,
      rememberMe: rememberMe,
    })

  postSignUp = (data) => axios.post(apiUrl('/v1/auth/signup'), data)

  getMe = () => axios.get(apiUrl('/v1/auth/me'))

  postResetPassword = (email) => axios.post(apiUrl('/v1/auth/reset'), { email: email })

  postConfirm = (action, token) =>
    axios.post(apiUrl('/v1/auth/confirm'), { action: action, token: token })

  postChangePassword = (token, newPassword, currentPassword) =>
    axios.post(apiUrl('/v1/auth/change-password'), {
      newPassword: newPassword,
      token: token,
      currentPassword: currentPassword,
    })

  postSignOut = () => axios.post(apiUrl('/v1/auth/signout'))

  postAvatar = (data) =>
    axios.post(apiUrl('/v1/auth/upload-avatar'), data, {
      header: { 'Content-Type': 'multipart/form-data' },
    })

  deleteAvatar = () => axios.delete(apiUrl('/v1/auth/delete-avatar'))

  patchUpdateProfile = (data) => axios.patch(apiUrl('/v1/auth/update'), data)

  postCloseAccount = () => axios.post(apiUrl('/v1/auth/close-account'))

  putUpdateLinks = (links, appearInSearch) =>
    axios.put(apiUrl('/v1/auth/update-links'), { links, appearInSearch })
}

export default AuthApiService
