import React from 'react'
import CenteredLayout from './CenteredLayout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type SplashPageProps = {
  errorMessage?: string
}

const SplashPage = ({ errorMessage }: SplashPageProps) => {
  return (
    <CenteredLayout classNames={'w-100 mx-auto text-center'}>
      {errorMessage ? (
        errorMessage
      ) : (
        <FontAwesomeIcon className={'text-5xl'} spin={true} icon={['fad', 'spinner-third']} />
      )}
    </CenteredLayout>
  )
}

export default SplashPage
