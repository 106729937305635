import axios from '../axios'
import { apiUrl } from '..'

class LessonApiService {
  getLessonData = (lessonId) => axios.get(apiUrl('/v1/lessons/view-full/' + lessonId))

  getLessons = (getParams) => axios.get(apiUrl(`/v1/lessons`, getParams))
}

export default LessonApiService
