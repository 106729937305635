import fetchAllLib from './fetchAll'
import AuthApiService from './auth'
import LessonApiService from './lesson'
import ExamApiService from './exam'
import QuestionApiService from './question'
import GeneralApiService from './general'
import UserApiService from './user'

export const apiUrl = (url, getParams = {}) => {
  const basePath = process.env.ACADEMATOR_API_URL

  let getParamsString = new URLSearchParams(getParams).toString()
  getParamsString = getParamsString ? `?${getParamsString}` : ''

  return basePath + url + getParamsString
}

export const audioUrl = (id) => apiUrl('/v1/audio/' + id + '.mp3')

export const fetchAll = fetchAllLib

class ApiService {
  constructor() {
    this.auth = new AuthApiService()
    this.user = new UserApiService()
    this.lesson = new LessonApiService()
    this.exam = new ExamApiService()
    this.question = new QuestionApiService()
    this.general = new GeneralApiService()
  }
}

const api = new ApiService()

export default api
