import React from 'react'
import Root, { RootProps } from '../Root'
import classnames from 'classnames'

type CenteredLayoutProps = {
  rootProps?: RootProps
  children?: React.ReactNode
  classNames?: string
  containerClassnames?: string
}

const CenteredLayout = ({
  children,
  classNames,
  containerClassnames,
  rootProps,
}: CenteredLayoutProps) => {
  return (
    <Root isHeightFull {...rootProps}>
      <div className={classnames('flex items-center h-full', containerClassnames)}>
        <div className={classnames(classNames)}>{children}</div>
      </div>
    </Root>
  )
}

export default CenteredLayout
