import { toast } from 'react-toastify'

const defaultOptions = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}

export const notificationSuccess = (message, delay = 0) => {
  if (delay === 0) {
    toast.success(message, defaultOptions)
  } else {
    setTimeout(() => toast.success(message, defaultOptions), delay)
  }
}

export const notificationInfo = (message, delay = 0) => {
  if (delay === 0) {
    toast.info(message, defaultOptions)
  } else {
    setTimeout(() => toast.info(message, defaultOptions), delay)
  }
}

export const notificationWarning = (message, delay = 0) => {
  if (delay === 0) {
    toast.warning(message, defaultOptions)
  } else {
    setTimeout(() => toast.warning(message, defaultOptions), delay)
  }
}

export const notificationError = (message, delay = 0) => {
  if (delay === 0) {
    toast.error(message, defaultOptions)
  } else {
    setTimeout(() => toast.error(message, defaultOptions), delay)
  }
}

export const notificationUnknownError = (error) => {
  notificationError('An unknown error occurred. Please contact us for assistance')
}
