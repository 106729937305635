// Trailing slash is important for freaking gatsby!

const ROUTES = {
  // Pages
  home: '/',
  ourStory: '/page/our-story/',
  terms: '/page/terms/',
  privacy: '/page/privacy/',
  cookies: '/page/cookies/',
  help: '/page/help/',

  // User
  signup: '/signup/',
  signin: '/signin/',
  passwordReset: '/reset/',
  confirm: '/confirm/',

  // dashboard
  dashboard: '/dashboard/',
  dashboardNotifications: '/dashboard/notifications/',
  dashboardLessons: '/dashboard/lessons/',
  dashboardTutors: '/dashboard/tutors/',
  dashboardReferrals: '/dashboard/referrals/',

  // Other
  settings: '/settings/',
  goBack: -1,
  profile: (username_slug) => '/profile/' + username_slug,
}

export default ROUTES
