import React, { useState, useEffect } from 'react'

const DEFAULT = 'light'

const LIGHT = 'light'
const DARK = 'dark'

export const MODES = {
  LIGHT,
  DARK,
}

const DEFAULT_MODE = LIGHT

const getInitialTheme = () => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const storedPrefs = window.localStorage.getItem('color-theme')

    if (typeof storedPrefs === 'string') {
      return storedPrefs
    }

    const userMedia = window.matchMedia('(prefers-color-scheme: dark)')

    if (userMedia.matches) {
      return DARK
    }
  }

  return DEFAULT_MODE
}

export const ThemeContext = React.createContext({
  theme: DEFAULT,
  setTheme: () => null,
})

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(getInitialTheme())

  useEffect(() => {
    localStorage.setItem('color-theme', theme)
  }, [theme])

  return <ThemeContext.Provider value={{ theme, setTheme }}>{children}</ThemeContext.Provider>
}
